@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  src: local("Montserrat"), url("./../fonts/Montserrat-Light.woff2") format("woff2"),
    url("./../fonts/Montserrat-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 300;
  src: url("./../fonts/Montserrat-LightItalic.woff2") format("woff2"),
    url("./../fonts/Montserrat-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("./../fonts/Montserrat-Regular.woff2") format("woff2"),
    url("./../fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  src: url("./../fonts/Montserrat-Italic.woff2") format("woff2"),
    url("./../fonts/Montserrat-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: url("./../fonts/Montserrat-SemiBold.woff2") format("woff2"),
    url("./../fonts/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 500;
  src: url("./../fonts/Montserrat-SemiBoldItalic.woff2") format("woff2"),
    url("./../fonts/Montserrat-SemiBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: url("./../fonts/Montserrat-Medium.woff2") format("woff2"),
    url("./../fonts/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 600;
  src: url("./../fonts/Montserrat-MediumItalic.woff2") format("woff2"),
    url("./../fonts/Montserrat-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url("./../fonts/Montserrat-Bold.woff2") format("woff2"), url("./../fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 700;
  src: url("./../fonts/Montserrat-BoldItalic.woff2") format("woff2"),
    url("./../fonts/Montserrat-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("./../fonts/Roboto-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("./../fonts/Roboto-Bold.woff2") format("woff2");
}

/* 
import "./fonts/Montserrat-Black.ttf";
import "./fonts/Montserrat-BlackItalic.ttf";
import "./fonts/Montserrat-Bold.ttf";
import "./fonts/Montserrat-BoldItalic.ttf";
import "./fonts/Montserrat-ExtraBold.ttf";
import "./fonts/Montserrat-ExtraBoldItalic.ttf";
import "./fonts/Montserrat-ExtraLight.ttf";
import "./fonts/Montserrat-ExtraLightItalic.ttf";
import "./fonts/Montserrat-Italic.ttf";
import "./fonts/Montserrat-Light.ttf";
import "./fonts/Montserrat-LightItalic.ttf";
import "./fonts/Montserrat-Medium.ttf";
import "./fonts/Montserrat-MediumItalic.ttf";
import "./fonts/Montserrat-Regular.ttf";
import "./fonts/Montserrat-SemiBold.ttf";
import "./fonts/Montserrat-SemiBoldItalic.ttf";
import "./fonts/Montserrat-Thin.ttf";
import "./fonts/Montserrat-ThinItalic.ttf"; */

@font-face {
  font-family: "Gotham";
  font-style: normal;
  font-weight: 900;
  src: url("./../fonts/Gotham-Ultra.ttf") format("truetype");
}

@font-face {
  font-family: "Gotham";
  font-style: italic;
  font-weight: 900;
  src: url("./../fonts/Gotham-UltraItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  font-style: normal;
  font-weight: 800;
  src: url("./../fonts/Gotham-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Gotham";
  font-style: italic;
  font-weight: 800;
  src: url("./../fonts/Gotham-BlackItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  font-style: normal;
  font-weight: 700;
  src: url("./../fonts/Gotham-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Gotham";
  font-style: italic;
  font-weight: 700;
  src: url("./../fonts/Gotham-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  font-style: normal;
  font-weight: 500;
  src: url("./../fonts/Gotham-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  font-style: italic;
  font-weight: 500;
  src: url("./../fonts/Gotham-MediumItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  font-style: normal;
  font-weight: 400;
  src: url("./../fonts/Gotham-Book.ttf") format("truetype");
}

@font-face {
  font-family: "Gotham";
  font-style: italic;
  font-weight: 400;
  src: url("./../fonts/Gotham-BookItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  font-style: normal;
  font-weight: 300;
  src: url("./../fonts/Gotham-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Gotham";
  font-style: italic;
  font-weight: 300;
  src: url("./../fonts/Gotham-LightItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  font-style: normal;
  font-weight: 200;
  src: url("./../fonts/Gotham-Thin.ttf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  font-style: italic;
  font-weight: 200;
  src: url("./../fonts/Gotham-ThinItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  font-style: normal;
  font-weight: 100;
  src: url("./../fonts/Gotham-XLight.ttf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  font-style: italic;
  font-weight: 100;
  src: url("./../fonts/Gotham-XLightItalic.ttf") format("truetype");
}
/* 
import "./fonts/Gotham-Black.ttf";
import "./fonts/Gotham-BlackItalic.ttf";
import "./fonts/Gotham-Bold.ttf";
import "./fonts/Gotham-BoldItalic.ttf";
import "./fonts/Gotham-Book.ttf";
import "./fonts/Gotham-BookItalic.ttf";
import "./fonts/Gotham-Light.ttf";
import "./fonts/Gotham-LightItalic.ttf";
import "./fonts/Gotham-Medium.ttf";
import "./fonts/Gotham-MediumItalic.ttf";

import "./fonts/Gotham-Thin.ttf";
import "./fonts/Gotham-ThinItalic.ttf";
import "./fonts/Gotham-Ultra.ttf";
import "./fonts/Gotham-UltraItalic.ttf";
import "./fonts/Gotham-XLight.ttf"; 
import "./fonts/Gotham-XLightItalic.ttf"; */
